import { createRouter, createWebHashHistory } from 'vue-router'

import config from  '../config/appcation'
// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes: [
        { path: '/', component: () => import('@/page/home') },
        { path: '/login', component: () => import('@/page/login') },
    ]
})

router.beforeEach((to,from,next)=>{
    // eslint-disable-next-line no-debugger
    let username = sessionStorage.getItem(config.sessionKey);//这里根据自己项目的实际情况添加是否登录的判断条件
    if (to.path !== '/login' && !username) {
        next({ path: '/login',query: { redirect: to.fullPath }})
    } else {
        next()
    }
})

export default router
